import React, { useState } from 'react'


const CustomInput = ({base64,setbase64,imageurl,setimageurl,file,setfile}) => {
 

  function getBase64(files) {

 files.forEach((obj,i)=>{

      var reader = new FileReader();
      reader.readAsDataURL(obj);
      reader.onload = function () {
        files[i]= reader.result.split(",")[1];
        
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };


    })

  return files;
   
  }
 
  const read = (e) =>{

   if(Array.from(e.target.files).length!=0)
   {
    
  
    if(Array.from(e.target.files).length>5)
    {

        alert("You can only upload a maximum of 5 files")
      return;
    }

    if(imageurl)
    {
      setimageurl("");
    }
    setfile(Array.from(e.target.files));

    
    setbase64(getBase64(Array.from(e.target.files)));

    
  }



}

console.log(base64)

console.log("check===>" , base64[0]?.length%4==0)

  return (<>
    <div className='custombtn'>

      <input type="text" className='simple' value={file.reduce((total,obj)=>total+obj.name+" , ","")}   />
     
      <input type='file' className='custom-file-input' accept=".jpg,.png"  onChange={(e)=>read(e)}  multiple/>
      
    
    </div>
   
    </>
  )
}

export default CustomInput