import { useEffect, useState } from "react";

import "./App.css";
import img from "./assets/logo.png";
import img2 from "./assets/Group.png";
import CustomInput from "./components/customInput/CustomInput";
import { classifierApi } from "./service/service";
import { generateAWSSignature } from "./service/getHeader";
import moment from "moment/moment";
import img3 from "./assets/loader.gif";
import ImagesSlider from "./components/inputimages/ImagesSlider";

import Outputimage from "./components/outputimage/Outputimage";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import img4 from "./assets/addrow.png";

import { AiOutlineUpload } from "react-icons/ai";
import Swal from "sweetalert2";
import img5 from "./assets/frame.svg"
import img6 from './assets/add.png'


const access_key = process.env.REACT_APP_API_ACCESS_KEY;
const secret_key = process.env.REACT_APP_API_SECRET_KEY;
function App() {
  const [count, setCount] = useState(0);
  const [base64, setbase64] = useState([]);
  const [data, setdata] = useState();

  const [loader, setloader] = useState(false);
  const [imageurl, setimageurl] = useState();
  
  const upload = () => {
    return new Promise((resolve, reject) => {
      setloader(true);
      const date = new Date();
      var myHeaders = new Headers();
      const base64Image = "your_base64_string";

      // Create the payload object
      const payload = {
        image: base64,
      };

      // const header = generateAWSSignature(
      //   "POST",
      //   "/endpoints/pytorch-inference-2023-10-03-13-32-44-614/invocations",
      //   "runtime.sagemaker.us-east-1.amazonaws.com",
      //   "sagemaker",
      //   "us-east-1",
      //   access_key,
      //   secret_key,
      //   JSON.stringify(payload)
      // );

      myHeaders.append("Content-Type", "application/json");

      // myHeaders.append(
      //   "X-Amz-Date",
      //   moment().utc().format("YYYYMMDDTHHmmss[Z]")
      // );
      // myHeaders.append("Authorization", header);
      // myHeaders.append("X-Amz-Content-Sha256",header['x-amz-content-sha256'])

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify(payload),
        redirect: "follow",
      };

      try {
        fetch(
          "http://54.190.195.24:5000/process_image",
          requestOptions
        )
          .then((response) => response.json())
          .then((responseJson) => {
            setloader(false);

            console.log(responseJson.result);
            setdata(Object.entries(responseJson.prediction_result.result));
            setimageurl(`data:image/jpeg;base64,${responseJson.prediction_result['image_base64']}`)
          })
          .catch((error) => {
            setloader(false);
            alert("Something went wrong. Try again")

          });
      } catch (error) {
        setloader(false);
        alert("Something went wrong")
        console.log(error);
      }
    });
  };

  const [file, setfile] = useState([]);
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };

  function sortArrayWithException(arr) {
    let totalbottles=  arr.filter(x=>x[0]=="Total Bottles")[0];

    let newarr = arr.filter(x=>x[0]!="Total Bottles");

    newarr.sort();
    newarr.push(totalbottles);
    

    return newarr;
  }

  const [editindex, seteditindex] = useState();

  const calculate = (index) => {
    if (tmpdata[0].length == 0 || tmpdata[0] === "" || tmpdata[0] === null || tmpdata[1]==="") {
      Swal.fire("Bottle name & Quantity must not empty", "Must enter  name & quantity", "question");
      return;
    }
    
    setIsnew(false)
    if (index != null) {
      data[index][0] = tmpdata[0];
      data[index][1] = tmpdata[1];
    }

    seteditindex("");
    console.log(data);
    var total = data.reduce((total, prev, index) => {
      if (index != data.length - 1) return total + parseFloat(prev[1]);
      else return total + 0;
    }, 0);

    data[data.length - 1][1] = total;

    setdata(data);
  };

  const [tmpdata, settmpdata] = useState();

  const manuplation = (index, key, value) => {
    console.log("asdfas");
    seteditindex(index);
    settmpdata([key, value]);
  };

  const addnewrow = () => {
    setdata([["", 0], ...data]);
    setIsnew(true);
    seteditindex(0);
    settmpdata(["", 0]);
    console.log(data);
  };

  const deletethis = (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        data.splice(index, 1);
        calculate();
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
      }
    });
  };

  const downloadCsv = () => {
    console.log("hello")
    let rows = [["Bottles", "Quantity"]];

    rows = [...rows, ...data];
    let csvContent =
      "data:text/csv;charset=utf-8," + rows.map((e) => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "sample.csv");
    document.body.appendChild(link); // Required for FF

    link.click();
  };

  const handleCancel = (index) => {
    if(tmpdata)


    if ((tmpdata[0] === null || tmpdata[0] === "") && isNew ) {
      data.splice(index, 1);
      setIsnew(false);
      seteditindex("");
    } else {
      seteditindex("");
    }
  };

  const [isNew, setIsnew] = useState(false);

  return (
    <>
      <div className="container">
        <div className="navbar">
          <img src={img} width={200} />
        </div>
        <div className="flex justify-center">
          <div
            className="flex justify-between"
            style={{ width: "70%", marginTop: 30 }}
          >
            <div>
              <CustomInput
                setimageurl={setimageurl}
                imageurl={imageurl}
                file={file}
                setfile={setfile}
                base64={base64}
                setbase64={setbase64}
              />
              {loader ? (
                <button className="upload">
                  <img src={img3} width={45} />{" "}
                </button>
              ) : (
                <button
                  className="upload cursor"
                  onClick={() => file.length > 0 && upload()}
                >
                  <AiOutlineUpload style={{ fontSize: 20 }} />
                  Upload
                </button>
              )}
            </div>
            <div>
              <div className="preview">
                <Outputimage imageurl={imageurl} onInit={onInit} />
              </div>

              <h4 style={{ margin: "5px 0px", textAlign: "center" }}>
                Preview
              </h4>
            </div>
          </div>
        </div>
       
        <div className="flex justify-center" style={{marginBottom:20}}>
          <ImagesSlider file={file} onInit={onInit} />
        </div>
        <div className="table flex flex-col items-center text-center">
          <div className="listof">
            <div className="myflex">
              <h2 style={{ fontFamily: "Montserrat" }} >List of stock</h2>
             
            </div>
            <div>
         {
          (data && !isNew)&&<img src={img5} style={{ color: "blue", cursor: "pointer" }}  onClick={()=> downloadCsv()} />
         }
                
          
           
            </div>
          </div>
          <table className="table">
            <tr>
              <th style={{ borderTopLeftRadius: 12 ,minWidth:280 }}>Bottles</th>
              <th>Quantity</th>
              <th style={{ borderTopRightRadius: 12 }}  onClick={()=>(data && !isNew) && addnewrow()}>   <img src={img6} className="addrow" /></th>
            </tr>
            <tbody>
              {data &&
                sortArrayWithException(data).map(
                  (obj, index) => {
                    return (
                      <tr>
                        <td
                          className="cursor"
                          onClick={() =>
                            (index != data.length - 1 && !isNew) &&
                            manuplation(index, obj[0], obj[1])
                          }
                        >
                          <span className="cursor">
                            {editindex === index ? (
                              <input
                                type="text"
                                className="editinput"
                                value={tmpdata[0]}
                                onChange={(e) =>
                                  settmpdata((prev) => [
                                    e.target.value,
                                    prev[1],
                                  ])
                                }
                                autoFocus
                              />
                            ) : (
                              obj[0]
                            )}
                          </span>
                        </td>
                        <td
                          className="cursor"
                             onClick={() =>
                            (index != data.length - 1 && !isNew) &&
                            manuplation(index, obj[0], obj[1])
                          }
                        >
                          <span>
                            {editindex === index ? (
                              <>
                                {" "}
                                <input
                                  className="editinput"
                                  style={{ maxWidth: 50, marginRight: 25 }}
                                  type="text"
                                  
                                  value={tmpdata[1]}
                                  onChange={(e) =>
                                    (e.target.value=="" || /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/.test(
                                      e.target.value
                                    ) )&&
                                    settmpdata((prev) => [
                                      prev[0],
                                      e.target.value,
                                    ])
                                  }
                                />
                              </>
                            ) : (
                              obj[1]
                            )}
                          </span>
                        </td>
                        <td style={{ width: 123, height: 12 }}>
                          <div className="flex">
                            {editindex === index ? (
                              <>
                                <button
                                  className="mybtn blue cursor"
                                  onClick={() => calculate(index)}
                                >
                                  SAVE
                                </button>
                                <button
                                  className="mybtn cursor"
                                  onClick={() => handleCancel(index)}
                                >
                                  CANCEL
                                </button>
                                {!isNew && (
                                  <button
                                    className="mybtn blue cursor"
                                    onClick={() => deletethis(index)}
                                  >
                                    Delete
                                  </button>
                                )}
                              </>
                            ) : (
                              <div style={{ width: 55, height: 12 }}></div>
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  }
                )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default App;
